import React from 'react';

function App() {
    return <div className="relative h-screen">
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-blue-950">
            <video src="https://frumentum-nl.objectdepot.net/background/switch.mp4" muted autoPlay loop
                   className="w-full h-full object-cover"/>
        </div>
        <div className="absolute z-10 left-0 right-0 top-0 bottom-0 flex">
            <div className="w-1/3 min-w-[200px] max-w-[300px] m-auto bg-white bg-opacity-50 rounded p-3 text-center">
                <img src="https://frumentum.objectdepot.net/logo/frumentum.svg" alt="Frumentum Media logo"/>
                This site is still under construction. Please check back later.
            </div>
        </div>
        <div className="absolute left-0 bottom-0 right-0 bg-black bg-opacity-75 z-20 p-5 text-white">
            <a href="mailto:hi@frumentum.nl" className="underline">hi@frumentum.nl</a>
            {' | '}
            <a href="https://as216107.net" target="_blank" rel="noreferrer" className="underline">AS216107</a>
            {' | '}
            KVK 91686164
        </div>
    </div>;
}

export default App;
